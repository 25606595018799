import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import {query, collection, getDocs, orderBy} from 'firebase/firestore';
import { db } from '../Admin/Config/Config';
import bannerDummy from '../Publication/assets/DUMMY.jpg';
import './Banner.css'
// Array of images and corresponding text
export default function Banner() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageList,setimageList] = useState([]);
  const dummy = [
    {
      image: bannerDummy,
      heading: "Loading..",
      quote: "",
      type:"",
      link:"",
      message:"",
      number:"",
    },
  ];
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [imageList]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    const data = [];
    const q = query(collection(db, 'banner'), orderBy('order', 'asc'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        image: doc.data().img_url,
        ...doc.data(),
      });
    });
    setimageList(data);
  }

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? imageList.length - 1 : prevIndex - 1
    );
  };

  const { image, type, link, message, number } = imageList[currentImageIndex]
    ? imageList[currentImageIndex]
    : dummy[0];

  return (
    <div style={{cursor: 'pointer'}} ref={ref} onClick={()=>{
      if(type&&type==='website'){
        window.open(link, '_blank');
      } else if(type&&type==='whatsapp'){
        window.open(
          `https://wa.me/${number}?text=${encodeURIComponent(message)}`
        );
      } 
    }} className="relative w-full max-w-7xl   mx-auto  px-5 py-5 overflow-hidden">
      {/* Image container with scroll-based animation */}
      {/* <motion.h1
            className="text-3xl sm:text-4xl ml-12 md:text-5xl font-bold mb-4"
            
            transition={{ duration: 0.5 }}
          >
            {heading}
          </motion.h1>
          <motion.p
            className="text-xl ml-12 sm:text-2xl"
            whileHover={{ scale: 1.05, color: '#FFD700' }} // Stunning animation on hover
            transition={{ duration: 0.5 }}
          >
            {quote}
          </motion.p> */}
          <motion.div
  className="relative overflow-hidden w-full  rounded-2xl banner-container h-[10rem] sm:h-[24rem] md:h-[28rem] lg:h-[30rem]"
  initial={{ opacity: 0 }}
  animate={inView ? { opacity: 1 } : { opacity: 0 }}
  transition={{ duration: 1.2, ease: 'easeInOut' }}
>
  <motion.div
    className="absolute inset-0 w-full h-full"
    key={currentImageIndex}
    initial={{ opacity: 0, scale: 1.2, x: 100 }}
    animate={{ opacity: 1, scale: 1, x: 0 }}
    exit={{ opacity: 0, scale: 0.8, x: -100 }}
    transition={{ duration: 1.2, ease: 'easeInOut' }}
  >
    <motion.img
      src={image}
      alt={`Slide ${currentImageIndex}`}
      className="w-full h-full object-cover banner-img" // Using object-cover for proper fit
    />
  </motion.div>

  {/* Text Overlay */}
  <motion.div
    className="absolute inset-0 flex flex-col items-start justify-center px-6 py-4 text-white text-left"
    initial={{ y: -100, opacity: 0 }}
    animate={inView ? { y: 0, opacity: 1 } : { y: -100, opacity: 0 }}
    transition={{ duration: 1.2, ease: 'easeOut' }}
  >
    {/* Add your overlay text here */}
  </motion.div>

  {/* Arrow Indicators */}
  <button
    onClick={goToPreviousImage}
    className="absolute w-12 h-12 left-6 top-1/2 transform -translate-y-1/2 text-white flex items-center justify-center rounded-full shadow-lg "
    whileHover={{ scale: 1.2, backgroundColor: '#e0e0e0' }}
    whileTap={{ scale: 0.9 }}
    transition={{ duration: 0.3 }}
  >
    &#8249;
  </button>
  <button
    onClick={goToNextImage}
    className="absolute w-12 h-12 right-6 top-1/2 transform -translate-y-1/2 text-white flex items-center justify-center rounded-full shadow-lg "
    whileHover={{ scale: 1.2, backgroundColor: '#e0e0e0' }}
    whileTap={{ scale: 0.9 }}
    transition={{ duration: 0.3 }}
  >
    &#8250;
  </button>
</motion.div>

    </div>
  );
}
