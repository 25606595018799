import React, { useEffect, useState } from 'react'
import img from '../Institutions/assets/BANNER.jpg'
import './css/Pub.css'
import logo from './assets/csi-removebg-preview.png'
import kani from './assets/kani.jpg'
import { collection, getDocs, orderBy, query } from 'firebase/firestore'
import { db } from '../Config/Config'
export default function Readings() {
  // ================================================ Data fetching ============================================= //
  const [showData, setShowData] = useState([]);
  const fetchData = async () => {
    const q = query(collection(db, "readings"), orderBy("order", "asc"));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setShowData(data);
  };

  useEffect(() => {
    fetchData();
  }, []);


  // ================================================ Download pdf ============================================= //

 const handleDownload = async (doc) => {
   try {
     const response = await fetch(doc.pdf_url);
     if (!response.ok) throw new Error("Network response was not ok");
     const blob = await response.blob();
     const url = window.URL.createObjectURL(blob);
     const link = document.createElement("a");
     link.href = url;
     link.download = `${doc.title}.pdf`;
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
     window.URL.revokeObjectURL(url);
   } catch (error) {
     console.error("Error downloading file:", error);
   }
 };


  return (
    <div>
      <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="flex items-center text-black">
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900 transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">READINGS</h2>
        </div>
      </div>

      <section>
        <div className="mx-auto w-full max-w-7xl px-8 md:px-10 py-6">
          <h2 class="text-xl px-6 py-6 font-medium md:text-2xl text-[#881337] md:leading-tight ">
            CSI READINGS
          </h2>

          <div className="flex flex-col items-center">
            <div className="grid gap-4  sm:grid-cols-2 sm:justify-items-stretch md:mb-16 md:grid-cols-3 lg:mb-20 lg:gap-6">
              {showData &&
                showData.map((item) => {
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      className="flex bg-gray-50 flex-col gap-4 rounded-md px-4 py-8 md:p-4"
                    >
                      <div className="relative">
                        <img
                          src={item.img_url?item.img_url:kani}
                          loading="lazy"
                          alt=""
                          className="inline-block h-60 w-full rounded-md object-cover"
                        />
                        <div className="absolute -bottom-7 right-4 flex h-16 w-16 items-center justify-center">
                          <img src={logo} alt="" className="inline-block" />
                        </div>
                      </div>
                      <div className="flex w-full flex-col gap-5">
                        <div className="rounded px-2 py-1.5">
                          <p className="text-lg font-medium text-[#000000]">
                            {item.title}
                          </p>
                        </div>
                        <div className="h-px w-full bg-black"></div>
                        <div className="flex justify-between items-center">
                          <a
                            href={item.pdf_url}
                            className="inline-block   px-8 py-2 border border-black text-black bg-white hover:bg-black hover:text-white transition-all duration-300"
                          >
                            View
                          </a>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={()=>handleDownload(item)}
                            className="inline-block  px-8 py-2 border border-black text-black bg-white hover:bg-black hover:text-white transition-all duration-300"
                          >
                            Download
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
