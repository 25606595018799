import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../Config/Config';
import backgroundImage from '../About/assets/kani2.jpg'; 
export default function Kaniv() {
  const [data,setData] = useState({});
  useEffect(() => {
    fetchKaniv();
  }, [])
  const fetchKaniv = async () => {
    const docRef = doc(db, "kaniv-pdf", "kaniv-text");
    const docSnap = await getDoc(docRef);
    setData(docSnap.data());
  }
  return (
    <div>
     <section>
     <div 
        className="flex py-12 md:py-12 lg:py-12 py-12 flex-col items-center justify-center px-5 md:px-10 bg-center bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${backgroundImage})` }} 
      >
        <div      className="mx-auto w-full max-w-5xl  bg-white bg-opacity-70 p-6 rounded-lg">
          <p className="mb-5 text-2xl text-[#881337] font-extrabold uppercase">
            {data.title}
          </p>
          <div className="mb-6 min-h-[1px] min-w-full bg-[#e2e2e2]"></div>

          <div className="mb-6 flex flex-col items-start gap-y-3">
            {data && data.content && data.content.map((contentItem, index) => (
              <p key={index} className="text-sm sm:text-base">{contentItem}</p>
            ))}
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}
