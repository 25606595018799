import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { db } from '../Config/Config';

export default function Readmore() {
  const location = useLocation();
  const [mainNews, setmainNews] = useState(location.state);
  // ========================================== NEWS CAROUSEL ========================================== //
  const [newsList, setnewsList] = useState([]);
  const fetchNews = async () => {
    const data = [];
    const q = query(collection(db, "news"), orderBy("order", "asc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
      setnewsList(data);
    });
  };

  useEffect(() => {
    fetchNews();
  }, []);
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  }

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="lg:flex lg:space-x-8">
          <div className="lg:w-2/3">
            <div className="bg-white rounded-lg overflow-hidden">
              <img
                src={mainNews.img}
                alt="Main News"
                className="w-full h-72 object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
              />
              <div className="p-6">
                <h1 className="text-2xl font-bold text-gray-900 leading-tight hover:text-gray-700 transition-colors duration-300">
                  {mainNews.title}
                </h1>
                {/* <div className="text-gray-500 text-sm mt-4">
                  Published on{" "}
                  {new Date(mainNews.time_stamp.seconds * 1000).toDateString()}
                  ,&nbsp;
                  {new Date(mainNews.time_stamp.seconds * 1000).toLocaleString(
                    "en-US",
                    {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }
                  )}
                </div>{" "} */}
                {/* Date and Time */}
                <p className="text-gray-700 mt-6 leading-relaxed">
                  {mainNews.content}
                </p>
              </div>
            </div>
          </div>

          <div className="lg:w-1/3 lg:mt-0">
            <div className="bg-white rounded-lg  ">
              <h2 className="text-lg font-bold text-gray-900 mb-6 border-b-2 border-gray-300 pb-2">
                More News
              </h2>
              <ul className="space-y-6">
                {/* Additional News 1 */}
                {newsList.map((news) => (
                  <li>
                    <div
                      onClick={() => setmainNews(news)}
                      className={`block ${
                        news.id === mainNews.id ? "bg-gray-200" : "bg-gray-50"
                      }  hover:bg-gray-200 rounded-lg p-4 transition-transform duration-300 ease-in-out transform hover:translate-x-1 cursor-pointer`}
                    >
                      <div className="flex items-center space-x-4">
                        <img
                          src={news.img}
                          alt="Additional News 1"
                          className="w-16 h-16 object-cover rounded-lg"
                        />
                        <div>
                          <h3 className="text-md font-semibold text-gray-900 hover:text-gray-700 transition-colors duration-300">
                            {truncateText(news.title, 20)}
                          </h3>
                          <p className="text-gray-700 text-sm mt-2">
                            {truncateText(news.content, 50)} <br/>read more
                          </p>
                          
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
