import React from 'react'
import { FaSchool } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import img from './assets/BANNER.jpg';
export default function Category() {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="flex items-center text-black">
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900  transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">SCHOOLS</h2>
        </div>
      </div>

      <div className="bg-gradient-to-r from-gray-100 to-gray-200 py-16">
        <div className="max-w-7xl mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="relative bg-white rounded-xl overflow-hidden shadow">
              <div
                className="absolute inset-0 bg-cover bg-center opacity-30"
                style={{
                  backgroundImage: "url('/path-to-aided-school-image.jpg')",
                }}
              ></div>
              <div className="relative p-8 z-10">
                <FaSchool className="text-5xl text-[#881337] mb-6" />
                <h2 className="text-3xl font-bold text-gray-800 mb-6">
                  Aided Schools
                </h2>
                <p className="text-gray-600 mb-8">
                  Discover the rich heritage and tradition of our aided schools,
                  offering quality education.
                </p>
                <button
                  onClick={() =>
                    navigate("/Schools", {
                      state: {
                        category: "Aided",
                      },
                    })
                  }
                  className="px-6 py-3 bg-[#881337] text-white font-semibold rounded-full shadow-lg"
                >
                  Explore Aided Schools
                </button>
              </div>
            </div>

            <div className="relative bg-white rounded-xl overflow-hidden shadow">
              <div
                className="absolute inset-0 bg-cover bg-center opacity-30"
                style={{
                  backgroundImage: "url('/path-to-unaided-school-image.jpg')",
                }}
              ></div>
              <div className="relative p-8 z-10">
                <FaSchool className="text-5xl text-[#881337] mb-6" />
                <h2 className="text-3xl font-bold text-gray-800 mb-6">
                  UnAided Schools
                </h2>
                <p className="text-gray-600 mb-8">
                  Explore the modern facilities and dedicated staff in our
                  unaided schools.
                </p>
                <button
                  onClick={() => navigate("/Schools", {
                    state: {
                      category: "Unaided",
                    },
                  })}
                  className="px-6 py-3 bg-[#881337] text-white font-semibold rounded-full shadow-lg"
                >
                  Explore UnAided Schools
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
