/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import img from './assets/BANNER.jpg';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../Config/Config';
import { useLocation } from 'react-router-dom';

export default function Highersecondary() {
  const [schoolList, setSchoolList] = useState([]);
  const [dataFlag, setDataFlag] = useState(true);
  const location = useLocation();

  const fetchData = async () => {
    const q = query(
      collection(db, "schools"),
      where("type", "==", location.state.name),
      where("category", "==", location.state.category),
      orderBy("order", "asc")
    );
    const querySnapshot = await getDocs(q);
    setDataFlag(querySnapshot.length > 0?true:false);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setSchoolList(data);
  }

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>


    
      <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="flex items-center text-black">
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900  transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">HIGHER SECONDARY SCHOOLS</h2>
        </div>
      </div>
      <div>
        

    



        
        <div class="container mx-auto p-6 bg-white text-gray-900">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            {schoolList.length > 0?
              schoolList.map((data) => {
                return (
                  <div class="bg-white p-6 rounded-lg border border-gray-200">
                    <div class="bg-gradient-to-r from-[#881337] to-[#5a2222] text-white py-3 px-4 rounded-t-lg">
                      <h2 class="text-xl font-semibold">{data.schoolname}</h2>
                    </div>
                    <div class="p-6 bg-white rounded-b-lg">
                      <p class="text-[#373737] mb-2">
                        <strong>Manager:</strong>
                        {data.manager}
                      </p>

                      <p class="text-[#373737] mb-2">
                        <strong>Principal:</strong> {data.principal}
                      </p>

                      <p class="text-[#373737] mb-2">
                        <strong>Administrative Officer:</strong>{" "}
                        {data.administrativeofficer}
                      </p>

                      <p class="text-[#373737] mb-2">
                        <strong>Phone:</strong> {data.phone}
                      </p>

                      <p class="text-[#373737] mb-2">
                        <strong>Address:</strong> {data.address}
                      </p>
                    </div>
                  </div>
                );
              }):!dataFlag?<h1>
                No Data Found
              </h1>:null}
          </div>
        </div>
      </div>
    </div>
  );
}
