import React from 'react';
import { FaChurch } from 'react-icons/fa';
import img from './assets/BANNER.jpg';
import { useNavigate } from 'react-router-dom';

export default function Churchcategory() {
    const navigate = useNavigate();
  const churchCategories = [
    { name: "South Malabar", description: "Under the CSI Malabar Diocese", color: "bg-gray-100", icon: <FaChurch className="w-12 h-12 text-[#881337]" /> },
    { name: "North Malabar", description: "Under the CSI Malabar Diocese", color: "bg-gray-100", icon: <FaChurch className="w-12 h-12 text-[#881337]" /> },
    { name: "Central Malabar", description: "Under the CSI Malabar Diocese", color: "bg-gray-100", icon: <FaChurch className="w-12 h-12 text-[#881337]" /> },
    { name: "Wayanad", description: "Under the CSI Malabar Diocese", color: "bg-gray-100", icon: <FaChurch className="w-12 h-12 text-[#881337]" /> },
  ];

  return (
    <div>
      <div
        className="w-full mx-auto px-8 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="flex items-center text-black">
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900 transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 1 1 0 4h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">Churches</h2>
        </div>
      </div>

      <section className="py-12 px-8 bg-white">
        <h2 className="text-3xl font-medium text-left mb-8 text-gray-800">
          Our Churches
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {churchCategories.map((church, index) => (
            <div
            style={{cursor:"pointer"}}
              onClick={() => navigate(`/churches`, { state: {
                church: church.name
              } })}
              key={index}
              className={`rounded-xl p-8 ${church.color} hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105`}
            >
              <div className="flex items-center mb-4">
                {church.icon}
                <h3 className="text-2xl font-semibold ml-4 text-gray-800">{church.name}</h3>
              </div>
              <p className="text-gray-700">{church.description}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
