import React, { useEffect, useState } from 'react'
import img from './assets/BANNER.jpg'
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Config/Config';
export default function Hospital() {
    const [hospitalData, setHospitalData] = useState([]);
    const fetchData = async () => {
        const docSnap = await getDoc(
          doc(db, "hospitals", "GjCOsl175TjuWNhoMnOg")
        );
        if (docSnap.exists()) {
          setHospitalData(docSnap.data());
        }
    }
    useEffect(() => {
        fetchData();
    },[])
  return (
    <div>
      <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="flex items-center text-black">
          <a
            href="/"
            className="flex font-semibold text-lg items-center text-gray-900 transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">Hospitals</h2>
        </div>
      </div>
      <div class="container  max-w-7xl   mx-auto px-4 p-6 bg-white text-gray-900">
        <div class="bg-white p-6 mb-8">
          <h1 class="text-4xl font-semibold text-[#881337] mb-4">
            {hospitalData && hospitalData.hospitalName}
          </h1>
          <div class="mb-4">
            <p class="text-lg">{hospitalData && hospitalData.address}</p>
            <p class="mt-2">
              Ph:{" "}
              <span class="font-medium">
                {hospitalData && hospitalData.phoneno}
              </span>
            </p>
            <p>
              Email:{" "}
              <a href="mailto:csihca@sify.com" class="text-blue-600 underline">
                {hospitalData && hospitalData.email}
              </a>
            </p>
            <p>
              <a
                href="http://www.csimissionhospital.com"
                target="_blank"
                class="text-blue-600 underline"
                rel="noreferrer"
              >
                {hospitalData && hospitalData.website}
              </a>
            </p>
          </div>

          <h2 class="text-3xl font-semibold text-[#881337] py-6">
            About CSI Mission Hospital
          </h2>
          {hospitalData &&
            hospitalData.about &&
            hospitalData.about.map((data) => {
              return <p class="text-gray-700 leading-relaxed mb-4">{data}</p>;
            })}
        </div>

        <div class=" bg-gray-50 rounded p-6 mb-8">
          <h2 class="text-3xl font-semibold text-[#881337] mb-6">
            Departments
          </h2>
          <ul class="grid grid-cols-1 md:grid-cols-2 gap-6">
            {hospitalData &&
              hospitalData.departments&&hospitalData.departments.map((data) => {
                return (
                  <li class="flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md">
                    <div class="w-10 h-10 flex items-center justify-center bg-[#881337] text-white rounded-full">
                      <svg
                        class="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        ></path>
                      </svg>
                    </div>
                    <p class="text-gray-700 font-medium">{data}</p>
                  </li>
                );
              })}
          </ul>
        </div>

        <div class="bg-white p-6 rounded-lg shadow-sm border-l-4 border-[#881337]">
          <p class="text-gray-700 leading-relaxed italic font-serif text-xl">
            "It is purely by God’s grace that we remain as a beacon of light to
            the population in this rural area, in spite of stiff competition by
            the corporate hospitals and other private hospitals that have come
            up in the nearby locations. Please continue to uphold us in your
            daily prayers."
          </p>
        </div>
      </div>
    </div>
  );
}
